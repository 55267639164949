const alertData = [
    {
        id: 1,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Normal',
    },
    {
        id: 2,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Urgent',
    },
    {
        id: 3,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Critique',
    },
    {
        id: 4,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Imminent',
    }
];

export { alertData };